<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ role.name }}</span>
    </div>

    <div>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-8">
          {{ role.name }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8">
          <div v-html="role.excerpt"></div>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="role.allowedLocations" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="role.organization" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
        <dd class="col-sm-8">
          <reseller :reseller="role.reseller" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="role.created_at">
          {{ $formatDate(role.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="role.updated_at">
          {{ $formatDate(role.updated_at) }}
        </dd>
      </dl>
      <dl class="row no-padding">
        <dt class="col-sm-12">{{ $t("COMMON.PERMISSIONS") }}</dt>
        <dd class="col-sm-12">
          <role-permissions :role="role" />
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import RolePermissions from "@/components/RolePermissions.vue";

export default {
  name: "role-view-global",

  components: { RolePermissions },

  props: ["role"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    role(role) {},
  },
};
</script>
